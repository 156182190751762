import React from "react";
import RootWrapper from "@/components/RootWrapper";

export const wrapRootElement = ({ element }) => <RootWrapper>{element}</RootWrapper>;

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm("This application has been updated. Do you want to load the latest version?");

  if (answer === true) {
    window.location.reload();
  }
};

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // don't update scroll if only query params are changed
  if (prevRouterProps && prevRouterProps.location.pathname === routerProps.location.pathname) {
    return false;
  }
  // default behaviour
  return true;
};
