module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","name":"Saman Loira","short_name":"Saman Loira","description":"Saman Loira is an Italian fashion brand with a romantic and sophisticated taste, which designs, produces and sells women's prèt-à-porter and demi-couture ceremony clothing.","lang":"en","icon":"src/images/icons/512x512.png","theme_color":"#F2F2F4","background_color":"#FFFFFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc49c4a39fddd2d3f2834e325db28d97"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-N0LP9X6BYR"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
