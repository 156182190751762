import React from "react";
import "focus-visible";
import "@fontsource/manrope/variable.css";
import "@/styles/global.css";
import { StoreProvider, StoreContext } from "@/components/StoreContext";
import Loader from "@/components/Loader";

export interface RootWrapperProps {
  /** Site content */
  children: React.ReactNode;
}

/** This component wraps the whole application in App/Test/Storybook environments. Pass all global providers here and add global imports at the top of the file */
const RootWrapper = ({ children }: RootWrapperProps) => {
  return (
    <StoreProvider>
      <StoreContext.Consumer>
        {({ isLoading }) => {
          return (
            <>
              {isLoading && <Loader />}
              {children}
            </>
          );
        }}
      </StoreContext.Consumer>
    </StoreProvider>
  );
};

export default RootWrapper;
