import React, { createContext, useState, useEffect } from "react";
import Client, { Client as ClientInterface, Cart, LineItemToAdd } from "shopify-buy";

const client =
  process.env.GATSBY_SHOPIFY_STORE_URL && process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
    ? Client.buildClient({
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      })
    : null;

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  open: () => {},
  close: () => {},
  client: null,
  checkout: null,
};

// @todo: what's the difference between checkout and cart??
export interface Context {
  // cart: any[];
  isOpen?: boolean;
  isLoading?: boolean;
  open: () => void;
  close: () => void;
  // onOpen: () => void;
  // onClose: () => void;
  addVariantToCart?: (variantId: string | number, quantity: string) => Promise<void>;
  removeLineItem?: (checkoutID: string | number, lineItemID: string | number) => Promise<void>;
  updateLineItem?: (checkoutID: string | number, lineItemID: string | number, quantity: string) => Promise<void>;
  client: ClientInterface | null;
  checkout: Cart | null;
  didJustAddToCart?: boolean;
}

export const StoreContext = createContext<Context>(defaultValues);

const isBrowser = typeof window !== "undefined";
const localStorageKey = "shopify_checkout_id";

interface Props {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: Props) => {
  const [checkout, setCheckout] = useState<Cart | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setCheckoutItem = (checkout: Cart) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, `${checkout.id}`);
    }

    setCheckout(checkout);
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      if (!client) {
        console.log("Checkout hasn't been initialised.");

        return;
      }

      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== "null") {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);

            return;
          }
        } catch (error) {
          console.error("Something went wrong while initialising checkout", error);
          localStorage.removeItem(localStorageKey);
        }
      }

      const newCheckout = await client.checkout.create();

      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  if (!client) {
    console.error(
      "Shopify client couldn't be initialised, make sure that GATSBY_SHOPIFY_STORE_URL, GATSBY_STOREFRONT_ACCESS_TOKEN are both defined."
    );
    return null;
  }

  // @todo: why quantity is a string?
  const addVariantToCart = async (variantId: string | number, quantity: string) => {
    if (!checkout) {
      console.error("Checkout isn't initialised yet.");

      return;
    }

    setIsLoading(true);

    const { id: checkoutID } = checkout;

    const lineItemsToUpdate: LineItemToAdd[] = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    const res = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate);

    setCheckout(res);
    setIsLoading(false);
    setDidJustAddToCart(true);
    setTimeout(() => setDidJustAddToCart(false), 3000);
  };

  const removeLineItem = async (checkoutID: string | number, lineItemID: string | number) => {
    setIsLoading(true);

    const res = await client.checkout.removeLineItems(checkoutID, [`${lineItemID}`]);

    setCheckout(res);
    setIsLoading(false);
  };

  const updateLineItem = async (checkoutID: string | number, lineItemID: string | number, quantity: string) => {
    setIsLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

    const res = await client.checkout.updateLineItems(checkoutID, lineItemsToUpdate);

    setCheckout(res);
    setIsLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        client,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        isLoading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
