import React from "react";

const Loader = () => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white bg-opacity-80" style={{ zIndex: 9999 }}>
      <svg
        className="absolute w-6 h-6 -ml-3 -mr-3 text-white animate-spin top-1/2 left-1/2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75 text-dove-gray"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

export default Loader;
